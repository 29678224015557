$(function () {
  $('.foal-result-script').on('click', '.hide_foal', function (e) {
    const checked = $(this).is(':checked');

    if (checked) {
      $('#foal').fadeOut();
    } else {
      $('#foal').fadeIn();
    }
  });

  $('.foal-result-script').on('click', '#twins', function (e) {
    const checked = $(this).is(':checked');

    if (checked) {
      $('.color_and_sex').fadeOut();
    } else {
      $('.color_and_sex').fadeIn();
    }
  });
});
