$(function () {
  window.checkAndConfirmStallion = function () {
    // Find selected stallion, by radio button
    const selected_stallion = $("input:radio.stallion:checked").val();

    // Check value for hidden fields
    const stallion_approved = $('#stallion_' + selected_stallion).val();
    const stallion_one_booking_available = $('#stallion_one_booking_available_' + selected_stallion).val();
    const stallion_cover_fee = $('#latest_cover_fee_for_' + selected_stallion).val();
    const stallion_additional_fee = $('#additional_fee_needed_' + selected_stallion).val();

    if (stallion_cover_fee == 'true') {
      return confirm("Du har valt en hingst som är verksam i tävling. Glöm inte att kontakta hingsthållaren för att säkerhetsställa tillgång av semin för ditt sto.");
    }

    if (stallion_additional_fee == 'true') {
      if (stallion_approved == 'true' && stallion_one_booking_available == 'true') {
        return confirm("Du har valt en hingst som saknar licens och kommer nu behöva teckna en ett-sto-licens.");
      } else {
        return confirm('Du har valt en ej godkänd hingst vars avkomma avses registreras i SWB. För att gå vidare med vald hingst måste du lösa en rapporteringsavgift. Avkomman registreras på s k "gråa papper".');
      }
    }

    return true;
  }
});

