$(function () {
  $('.stallions-competitions-script').on('click', '#competes', function (e) {
    const checked = $(this).is(':checked');

    if (checked) {
      $('#competition_info').show();
    } else {
      $('#competition_info').hide();
    }
  });
});
