$(document).ready(function () {
  // Targets table radiobuttons and go to next page
  // Bookings
  $('#mare-result tr').on('click', function () {
    let id = $(this).closest('tr').find('input')[0].id;
    $("#" + id).prop("checked", "checked");
    $('input[name=\"booking[external_mare_id]\"]').val(id);

    $(this).parents('form:first').submit();
  });

  $('#cover-station-result tr').on('click', function () {
    let id = $(this).closest('tr').find('input')[0].id;
    $("#" + id).prop("checked", "checked");
    $(this).parents('form:first').submit();
  });

  $('#stallion-result tr').on('click', function (e) {
    e.preventDefault();

    var id = $(this).closest('tr').find('input')[0].id;
    $("#" + id).prop("checked", "checked");
    $('input[name=\"booking[external_stallion_id]\"]').val(id);

    if (checkAndConfirmStallion() === true) {
      $(this).parents('form:first').submit();
    }
  });

  // Stallion keeper fees
  $('#stallion-keeper-fees-stallion-result tr').on('click', function (e) {
    e.preventDefault();

    var id = $(this).closest('tr').find('input')[0].id;
    $("#" + id).prop("checked", "checked");

    if (checkAndConfirmStallion() === true) {
      $(this).parents('form:first').submit();
    }
  });

  $('#build_mare').on('click', function () {
    $(this).parents('form:first').submit();
  });
});
