$(function () {
  $('.foal-booking-script').on('click', '.show_foal', function (e) {
    const checked = $(this).is(':checked');

    if (checked) {
      $('#foal_info').show();
    } else {
      $('#foal_info').hide();
    }
  });
});
