window.getParameterByName = function (name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

window.addEventListener('open-takeover', function (evt) {
  var takeoverContainer = document.getElementsByClassName('takeover-container')[0];
  takeoverContainer.style.display = 'block';
  takeoverContainer.classList.add('active');
}, false);

window.addEventListener('close-takeover', function (evt) {
  var takeoverContainer = document.getElementsByClassName('takeover-container')[0];
  takeoverContainer.style.display = 'none';
  takeoverContainer.classList.remove('active');
}, false);
