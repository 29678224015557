// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require('jquery')
require('bootstrap')

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'bootstrap-icons/font/bootstrap-icons.css'

import 'channels'
import 'dropdown_menus'

import 'foals/foal_result'
import 'foals/foal_booking'
import 'table/table_row'
import 'custom/custom'
import 'tags/toggle'
import 'stallions/competitions'
import 'stallions/confirms'
import 'form_helpers'
import 'takeover/takeover'

import "../stylesheets/application.scss";

Rails.start()
ActiveStorage.start()
